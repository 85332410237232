import React, { useEffect, useState } from 'react'
import NewsHero from '../Common/NewsHero'
import Entertain1 from '../../../images/personaimg/Temp/ent1.webp';
import Entertain2 from '../../../images/personaimg/Temp/ent2.jpg';
import img from '../../../images/personaimg/Temp/ent3.jpg';
import img2 from '../../../images/personaimg/Temp/Ent1.jpg';
import { ShimmerPostItem } from 'react-shimmer-effects';
import { Container } from 'reactstrap';

const EntertainHome = () => {
    const [mainNews, setMainNews] = useState([]);
    const [secondaryNews, setSecondaryNews] = useState([]);
    const [headlines, setHeadlines] = useState([]);
    const [trendingNewsData, setTrendingNewsData] = useState([]);
    const [recentNewsData, setRecentNewsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchNewsData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_NEWS_API_URL}api/v1/public/get-public-news?category=entertainment&limit=25&order=desc`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            if (data.status && data.data) {
                setMainNews(data.data.news && data.data.news.length > 0 ? data.data.news[0] : staticMainNews);
                setTrendingNewsData(data.data.news.length > 0 ? data.data.news.slice(1, 5) : staticTrendingNewsData);
                setSecondaryNews(data.data.news && data.data.news.length > 0 ? data.data.news.slice(5, 6) : staticSecondaryNews);
                setHeadlines(data.data.news && data.data.news.length > 0 ? data.data.news.slice(6, 15) : staticHeadlines);
                setRecentNewsData(data.data.news && data.data.news.length > 0 ? data.data.news.slice(15, 19) : staticRecentNewsData);
            } else {
                setFallbackData();
            }
        } catch (error) {
            console.error("Fetch error:", error);
            setFallbackData();
        } finally {
            setLoading(false);
        }
    };

    const setFallbackData = () => {
        setMainNews(staticMainNews);
        setSecondaryNews(staticSecondaryNews);
        setHeadlines(staticHeadlines);
        setTrendingNewsData(staticTrendingNewsData);
        setRecentNewsData(staticRecentNewsData);
    };

    useEffect(() => {
        fetchNewsData();
    }, []);

    const staticMainNews = [
        { id: 1, image: Entertain1, headline: "Ryan Reynolds had a few questions for Blake Lively’s ‘It Ends with Us’ leading man" }
    ];

    const staticSecondaryNews = [
        { id: 1, image: Entertain2, headline: "‘The Sixth Sense’ turns 25. Here’s a list of M. Night Shyamalan’s freak movie endings, from ridiculous to incredible" }
    ];

    const staticHeadlines = [
        { id: 1, headline: "Kamala Harris praised ‘defund the police’ movement in June 2020 radio interview" },
        { id: 2, headline: "How Doug Emhoff is amplifying his wife’s historic presidential bid" },
        { id: 3, headline: "How Kamala Harris’ warp-speed campaign launch has changed the 2024 race" },
        { id: 4, headline: "FBI says investigators are still examining evidence from Trump assassination attempt" },
        { id: 5, headline: "Harris and Trump at odds over presidential debate" },
        { id: 6, headline: "Biden and Netanyahu meet with urgency to reach ceasefire deal at top of the agenda" },
        { id: 7, headline: "Harris steps out on Israel as she navigates Biden and Netanyahu" },
    ]

    const staticTrendingNewsData = [
        { id: 1, image: img2, title: "Entertainment News 1", text: "‘House of the Dragon’ boss says Season 3 is ‘going to be about total war’" },
        { id: 2, image: img, title: "Entertainment News 2", text: "Content for the second Entertainment news item goes here." },
        { id: 3, image: img2, title: "Entertainment News 3", text: "Here's what the third Entertainment news item is about." },
        { id: 4, image: img, title: "Entertainment News 4", text: "Here's what the third Entertainment news item is about." },
    ];

    const staticRecentNewsData = [
        { id: 1, image: img2, title: "Recent News 1", text: "Swifties find solace in song and solidarity after terror threat leads to cancelled shows in Vienna" },
        { id: 2, image: img, title: "Recent News 2", text: "‘House of the Dragon’ boss says Season 3 is ‘going to be about total war’" },
        { id: 3, image: img2, title: "Recent News 3", text: "Here's what the third recent news item is about." },
        { id: 4, image: img, title: "Recent News 4", text: "Fourth recent item's content is displayed here." },
    ];

    return (
        <>
            {loading ? (
                <Container>
                    <div className='row'>
                        <div className="col-6">
                            <ShimmerPostItem card title text cta />
                        </div>
                        <div className="col-3">
                            <ShimmerPostItem card title cta />
                        </div>
                        <div className="col-3">
                            {[...Array(2)].map((_, index) => (
                                <ShimmerPostItem
                                    card
                                    title
                                    cta
                                    imageType="thumbnail"
                                    imageWidth={80}
                                    imageHeight={80}
                                    contentCenter
                                />
                            ))}
                        </div>
                    </div>
                </Container>
            ) : (
                <NewsHero
                    title="Entertainment"
                    mainNews={mainNews}
                    secondaryNews={secondaryNews}
                    headlines={headlines}
                    trendingNewsData={trendingNewsData}
                    recentNewsData={recentNewsData}
                />
            )}
        </>
    )
}

export default EntertainHome
