import React from 'react';
import SportsHome from './sportComp/SportsHome';
import SportCatagories from './sportComp/SportCatagories';
import ScrollToTopButton from '../components/common/ScrollToTopButton';
import HomeLink from '../components/common/HomeLink';

const Sports = () => {
    return (
        <>
            <SportsHome />
            <SportCatagories />
            <HomeLink />
            <ScrollToTopButton />
        </>
    )
}

export default Sports
