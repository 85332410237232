import React from 'react';
import { Col, Row } from 'reactstrap';
import { Icon } from '../../../Components/Component';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../images/personaimg/Persona_Digest_light.png';

const FooterNew = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };
    return (
        <div className='nk-footer text-center border-top border-3 border-primary mt-3'>
            <div className="container-xl wide-xl">
                <div className='nk-footer-wrap justify-content-start g-5'>
                    <Col md={12} className='d-flex justify-content-lg-start'>
                        <Link to="/" className="scrollto logo-link mx-2">
                            <img src={logo} alt="Logo" style={{ height: "50px" }} />
                        </Link>
                    </Col>
                    <Col md={12} className='text-start d-flex py-2'>
                        <ul className='d-flex justify-content-md-around'>
                            <li className={`p-2 ${isActive('/')}`}>
                                <Link to="/" className='text-black text-decoration-none'>
                                    Home
                                </Link>
                            </li>
                            <li className={`p-2 ${isActive('/sports')}`}>
                                <Link to="/sports" className='text-black text-decoration-none'>
                                    Sports
                                </Link>
                            </li>
                            <li className={`p-2 ${isActive('/politics')}`}>
                                <Link to="/politics" className='text-black text-decoration-none'>
                                    Politics
                                </Link>
                            </li>
                            <li className={`p-2 ${isActive('/entertainment')}`}>
                                <Link to="/entertainment" className='text-black text-decoration-none'>
                                    Entertainment
                                </Link>
                            </li>
                            {/* <li className={`p-2 ${isActive('/health')}`}>
                                <Link to="/health" className='text-black text-decoration-none'>
                                    Health
                                </Link>
                            </li>
                            <li className={`p-2 ${isActive('/business')}`}>
                                <Link to="/business" className='text-black text-decoration-none'>
                                    Business
                                </Link>
                            </li>
                            <li className={`p-2 ${isActive('/technology')}`}>
                                <Link to="/technology" className='text-black text-decoration-none'>
                                    Technology
                                </Link>
                            </li>
                            <li className={`p-2 ${isActive('/travel')}`}>
                                <Link to="/travel" className='text-black text-decoration-none'>
                                    Travel
                                </Link>
                            </li> */}
                        </ul>
                    </Col>
                    <Col md={12} className='text-start'>
                        <div className='p-1 d-flex'>
                            <p className='ff text-black align-center fw-light mb-0 px-1' style={{ letterSpacing: '2px', fontSize: '15px' }}>FOLLOW US ON : </p>
                            <div className='ms-0'>
                                <Link to="https://www.facebook.com/Persona-Digest-107687561155151/">
                                    <Icon name='facebook-circle' className='fs-1 icon-hover facebook px-1' />
                                </Link>
                                <Link to="https://www.instagram.com/persona_digest/">
                                    <Icon name='instagram-round' className='fs-1 icon-hover instagram px-1' />
                                </Link>
                                <Link to="https://x.com/PersonaDigest">
                                    <Icon name='twitter-round' className='fs-1 icon-hover twitter px-1' />
                                </Link>
                                <Link to="https://www.youtube.com/channel/UCbv896Op069uX9VA2VdPf5g">
                                    <Icon name='youtube-round' className='fs-1 icon-hover youtube px-1' />
                                </Link>
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
        </div>
    )
}

export default FooterNew
