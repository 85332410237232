import React, { useEffect, useState } from 'react';
import { getRequest } from '../../../utils/ApiService';
import { ShimmerTitle } from 'react-shimmer-effects';

const API_URL = `${process.env.REACT_APP_NEWS_API_URL}api/v1/public/get-public-news?limit=5&order=asc`;

const Headline = () => {
    const [headlines, setHeadlines] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHeadlines = async () => {
            try {
                const response = await getRequest(API_URL);
                setHeadlines(response.data.news || []);
                // console.log(response.data.news);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching headlines:", error);
                setError(error.response?.data?.message || error.message);
                setLoading(false);
            }
        };

        fetchHeadlines();
    }, []);

    if (loading) return <div><ShimmerTitle line={1} gap={15} variant="primary" /></div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="headline-container">
            <div className="marquee container-fluid d-flex align-center h-100">
                <div className="track d-flex align-center bg-danger-subtle py-1">
                    {headlines.map((headline, index) => (
                        <React.Fragment key={index}>
                            <span className="headline-item">
                                {headline.title}
                            </span>
                            {index !== headlines.length - 1 && (
                                <span className="separator">&nbsp;★&nbsp;</span>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Headline;


