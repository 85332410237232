import React, { useEffect, useState, useCallback } from 'react';
import { NewsContext } from './NewsContext';

const NewsStates = ({ children }) => {
    const [data, setData] = useState(null);
    const [dataSearch, setDataSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const url = new URL(`${process.env.REACT_APP_NEWS_API_URL}api/v1/public/get-public-news`);
            if (dataSearch) {
                url.searchParams.append("search_keyword", dataSearch);
            }
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            setData(responseData?.data?.news || []);
        } catch (error) {
            setError("Failed to fetch news. Please try again later.");
        } finally {
            setLoading(false);
        }
    }, [dataSearch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = (searchTerm) => {
        setDataSearch(searchTerm);
    };

    return (
        <NewsContext.Provider value={{ 
            data, 
            loading, 
            error, 
            setDataSearch: handleSearch,
            refreshData: fetchData 
        }}>
            {children}
        </NewsContext.Provider>
    );
};

export default NewsStates;