import React, { useMemo } from 'react';
import { Card, CardBody, CardFooter, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import placeholderimage from '../../../images/News/NewsDefault.png'

const NewsItem = React.memo(({ isLast, image, title, text, id, date, onError }) => (
    <div className=''>
        <Link to={`/news/${id}`}>
            <div className={`d-flex flex-column flex-md-row image-zoom-container border-bottom border-bottom-2 border-bottom-light py-2`}>
                <div className='col-12 col-md-4 mb-3 mb-md-0 image-zoom-container rounded-0'>
                    <figure className='h-100 m-0 rounded-0'>
                        <img src={image || placeholderimage} alt={title} className='zoom-image rounded-0 w-100 h-100' loading="lazy" onError={onError} />
                    </figure>
                </div>
                <div className='col-12 col-md-8'>
                    <Card className='rounded-0 h-100'>
                        <CardBody className="card-inner h-100 d-flex flex-column justify-content-start p-3">
                            <CardTitle tag="h5" className='text-black ff m-0'>{title}</CardTitle>
                            <CardText>{text}</CardText>
                        </CardBody>
                        <CardFooter className='bg-transparent text-gray py-0 text-end'>
                            {new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                        </CardFooter>
                    </Card>
                </div>
            </div>
        </Link>
    </div>
));

const RecentNewsCard = React.memo(({ isLast, image, title, id, date, onError }) => (
    <div className='border border-2 border-light rounded-3 p-2 mb-3'>
        <Card className={`hover01 rounded-0 h-100`}>
            <Link to={`/news/${id}`}>
                <div className="hover01">
                    <figure className="m-0">
                        <CardImg top src={image || placeholderimage} alt={title} loading="lazy" className="rounded-0 w-100" onError={onError} />
                    </figure>
                </div>
                <CardBody className="card-inner p-1">
                    <CardText tag="h5" className='text-black'>{title}</CardText>
                </CardBody>
                <CardFooter className='bg-transparent text-gray p-0 text-end'>
                    {new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                </CardFooter>
            </Link>
        </Card>
    </div>

));

const NewsHero = ({ title, mainNews, secondaryNews, headlines, trendingNewsData, recentNewsData }) => {

    const placeholderImage = placeholderimage;

    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    };

    const newsItems = useMemo(() => {
        return trendingNewsData?.map((item, index) => (
            <NewsItem
                key={item.id}
                id={item.id}
                isLast={index === trendingNewsData.length - 1}
                image={item.image || placeholderImage}
                title={item.title}
                text={item.text}
                date={item.publish_date}
                onError={handleImageError}
            />
        )) || [];
    }, [trendingNewsData]);

    const recentNewsCards = useMemo(() => {
        return recentNewsData?.map((item, index) => (
            <RecentNewsCard
                key={item.id}
                id={item.id}
                isLast={index === recentNewsData.length - 1}
                image={item.image || placeholderImage}
                text={item.text}
                title={item.title}
                date={item.publish_date}
                onError={handleImageError}
            />
        )) || [];
    }, [recentNewsData]);

    return (
        <div className='nk-content py-0'>
            {title && (
                <div className='container'>
                    <h2 className='ff text-primary'>{title}</h2>
                </div>
            )}
            <div className='container'>
                <Row className='py-3'>
                    {mainNews && (
                        <Col lg={6}>
                            <div>
                                <div className='border border-2 rounded-3 p-2'>
                                    <Card className='hover01 rounded-0'>
                                        <Link to={`/news/${mainNews.id}`}>
                                            <div className="hover01">
                                                <figure className="m-0">
                                                    <img src={mainNews.image || placeholderImage} alt={mainNews.title} className="w-100" loading="lazy" onError={handleImageError} />
                                                </figure>
                                            </div>
                                            <div className='py-3'>
                                                <h4 className='ff text-black'>{mainNews.title}</h4>
                                                <CardFooter className='bg-transparent text-gray p-0 text-end'>
                                                    {new Date(mainNews.publish_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                                                </CardFooter>
                                            </div>
                                        </Link>
                                    </Card>
                                </div>
                                {newsItems.length > 0 && (
                                    <div className='mt-3'>
                                        <div className="trending-news">
                                            <Row className=''>{newsItems}</Row>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Col>
                    )}
                    {(secondaryNews || headlines) && (
                        <Col lg={3} className='px-1'>
                            {secondaryNews?.map((news) => (
                                <div key={news.id}>
                                    <div className='border border-2 rounded-3 p-2'>
                                        <Card className='hover01 rounded-0'>
                                            <Link to={`/news/${news.id}`}>
                                                <figure className='m-0'>
                                                    <img src={news.image || placeholderImage} alt={news.title} className="w-100" loading="lazy" onError={handleImageError} />
                                                </figure>
                                                <div className='pt-3'>
                                                    <h5 className='ff text-black'>{news.title}</h5>
                                                    <CardFooter className='bg-transparent text-gray p-0 text-end'>
                                                        {new Date(news.publish_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                                                    </CardFooter>
                                                </div>
                                            </Link>
                                        </Card>
                                    </div>
                                    {headlines?.map((newsItem) => (
                                        <div key={newsItem.id} className='p-2 border-bottom border-bottom-2'>
                                            <Link to={`/news/${newsItem.id}`} className="news-link">
                                                <div className={`d-flex flex-column flex-md-row`}>
                                                    <Card className='rounded-0 h-100'>
                                                        <CardBody className="card-inner p-0">
                                                            <CardText className='text-black fs-4 ff'>{newsItem.title}</CardText>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Col>
                    )}
                    {recentNewsCards.length > 0 && (
                        <Col lg={3}>
                            <div className="recent-news">
                                {recentNewsCards}
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}

export default NewsHero
