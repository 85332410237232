import React, { useEffect } from 'react';
import Hero from './components/Home/Hero';
import RecentNews from './components/Home/RecentNews';
import MoreNews from './components/Home/MoreNews';
import ScrollToTopButton from './components/common/ScrollToTopButton';
import Categories from './components/Home/Categories';
import Headline from '../layout/common/navbar/Headline';
import HomeLink from './components/common/HomeLink';


const News = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <React.Fragment>
            <Headline />
            <Hero />
            <RecentNews />
            <MoreNews />
            <Categories />
            <HomeLink />
            <ScrollToTopButton />
        </React.Fragment >
    )
}

export default News
