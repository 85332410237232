import React, { useState, useEffect, useCallback, useMemo } from "react";
import Blogitem from "./Blogitem";
import { Link, useNavigate } from "react-router-dom";
import "./Blog.css";
import { getCookie } from "../utils/Utils";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../src/assets/css/blog.css";
import "./Blog.css";
import { ShimmerContentBlock } from "react-shimmer-effects";

const ITEMS_PER_PAGE = 3;

const categories = [
  { id: 0, name: "TECH TRENDS" },
  { id: 1, name: "ARTIFICIAL INTELLIGENCE" },
  { id: 2, name: "PRODUCT REVIEWS" },
  { id: 3, name: "CLOUD COMPUTING" },
  { id: 4, name: "TECH NEWS" },
  { id: 5, name: "TECH CONFERENCES" },
];

const generateEditor = (description) => {
  try {
    const contentState = convertFromRaw(JSON.parse(description));
    let truncatedText = contentState.getPlainText().substring(0, 150);
    if (contentState.getPlainText().length > 150) {
      truncatedText += "...";
    }
    const truncatedContentState = convertFromRaw({
      entityMap: {},
      blocks: [
        {
          text: truncatedText,
          type: "unstyled",
          entityRanges: [],
        },
      ],
    });
    const truncatedEditorState = EditorState.createWithContent(truncatedContentState);
    return (
      <Editor editorState={truncatedEditorState} toolbarHidden readOnly />
    );
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return <p>Error parsing description</p>;
  }
};

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryCount, setCategoryCount] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const fetchBlogs = useCallback(async () => {
    try {
      setLoading(true);
      const authToken = getCookie("authToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-list-blogs?current_page=${currentPage}&per_page=${ITEMS_PER_PAGE}&category=${selectedCategory}&search=${searchTerm}`,
        {
          headers: {
            authToken: authToken,
          },
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch blogs");
      }

      const data = await response.json();
      // console.log("API Response:", data);
      if (data.status) {
        setBlogs(data.data.blogs);
        setTotalPages(data.data.pagination.last_page);
        // console.log("Total Pages:", data.data.pagination.last_page); 
      } else {
        throw new Error(data.message || "Failed to fetch blogs");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, selectedCategory, searchTerm]);

  const fetchCategoryCounts = useCallback(async () => {
    try {
      const authToken = getCookie("authToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-category-count`,
        {
          headers: {
            authToken: authToken,
          },
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch category counts");
      }

      const data = await response.json();
      if (data.status) {
        const counts = {};
        data.data.forEach(item => {
          counts[item.category] = item.count;
        });
        setCategoryCount(counts);
      } else {
        throw new Error(data.message || "Failed to fetch category counts");
      }
    } catch (err) {
      console.error("Error fetching category counts:", err);
    }
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, [fetchBlogs]);

  useEffect(() => {
    fetchCategoryCounts();
  }, [fetchCategoryCounts]);

  const handlePageChange = useCallback((page) => {
    // console.log("Changing to page:", page); 
    setCurrentPage(page);
    window.scrollTo(0, 0);
  }, []);
  const handleCategoryClick = useCallback((category) => {
    setSelectedCategory(category);
    navigate(`/category/${category}`);
  }, []);

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  }, []);

  const recentPosts = useMemo(() => blogs.slice(0, 3), [blogs]);

  const renderBlogs = useMemo(() => {
    return blogs.map((blog, index) => {
      const isVideo = !blog.blog_image || blog.blog_image === null || blog.blog_image.trim() === "";
      return (
        <div key={blog.id}>
          <Blogitem
            id={blog.id}
            title={blog.title}
            author={blog.author}
            description={generateEditor(blog.description)}
            long_description={blog.description}
            video={blog.blog_video}
            Img={blog.blog_image}
            is_video={isVideo}
            created_at={blog.created_at}
            position={index % 2 === 0 ? "right" : "left"}
          />
          <hr className="silo-divider" />
        </div>
      );
    });
  }, [blogs]);

  const renderPagination = useMemo(() => {
    // console.log("Rendering pagination, totalPages:", totalPages);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <nav className="mt-4" aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
          </li>
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(number)}>{number}</button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>
    );
  }, [currentPage, totalPages, handlePageChange]);

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="blog mb-5 pt-3 container">
      <div className="row d-flex justify-content-between my-5">
        <div className="col-md-8 border border-1 rounded-4 col-12 blogs p-3 m-0">
          <input
            type="text"
            placeholder="Search blogs..."
            onChange={handleSearch}
            className="form-control mb-3"
          />
          {loading ? (
            <ShimmerContentBlock title text cta thumbnailWidth={370} thumbnailHeight={370} />
          ) : (
            renderBlogs
          )}
          {renderPagination}
        </div>
        <div id="sidebar" className="col-md-4 col-12">
          <div className="sticky-top row d-flex justify-content-center">
            <div className="">
              <div className="d-flex flex-column px-2 py-2 border rounded-4">
                <div className="rounded px-2 py-2 mt-2 text-start fw-bold text-light me-auto"
                  style={{
                    background: process.env.REACT_APP_BUTTON_BACKGROUND_COLOR,
                  }}
                >
                  Recent Posts
                </div>
                <ul className="list-group my-2 text-start list-group-flush">
                  {recentPosts.map((blog, index) => (
                    <li
                      key={index}
                      className="list-group-item my-2 rounded border-bottom-0"
                      style={{
                        borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`,
                        backgroundColor: process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR,
                      }}
                    >
                      <Link
                        to={`/Blogdetails/${blog.id}`}
                        className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                        style={{ textAlign: "justify" }}
                      >
                        {blog.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="d-flex flex-column px-2 py-2 border rounded-4 mt-4">
                <div
                  className="rounded px-2 py-2 mt-2 text-start fw-bold text-light me-auto"
                  style={{ background: process.env.REACT_APP_BUTTON_BACKGROUND_COLOR, }}
                >
                  Categories
                </div>
                <ul className="list-group my-2 text-start list-group-flush">
                  {categories.map((category, index) => (
                    <li
                      key={index}
                      className="list-group-item my-3 rounded border-bottom-0"
                      style={{
                        borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`,
                        backgroundColor:
                          process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR,
                      }}
                    >
                      <p
                        className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                        onClick={() => handleCategoryClick(category.name)}
                        style={{ textAlign: "justify", cursor: 'pointer' }}
                      >
                        {category.name}({categoryCount[category.name]})
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <h6 className="text-center">© {currentYear} {process.env.REACT_APP_PROJECT_NAME_Blog} | All Rights Reserved</h6>
      </div>
    </div >
  );
}