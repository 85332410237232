import React, { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { NewsContext } from '../../layout/common/navbar/context/NewsContext';
import { Card, CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import placeholderimage from '../../images/News/NewsDefault.png';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';

const SearchNews = () => {
    const { data, loading, error, setDataSearch } = useContext(NewsContext);
    const { searchTerm } = useParams();

    useEffect(() => {
        if (searchTerm) {
            setDataSearch(searchTerm);
        }
    }, [searchTerm, setDataSearch]);
    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };
    const handleImageError = (event) => {
        event.target.src = placeholderimage;
    };

    if (loading) {
        return (
            <div className="container">
                {[...Array(3)].map((_, index) => (
                    <ShimmerSimpleGallery card imageHeight={300} caption />
                ))}
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            {data && data.length > 0 ? (
                <Container>
                    <h4>Search Results for: {searchTerm}</h4>
                    <Row className='g-3'>
                        {data.map((news) => {
                            const { id, title, summary, image, category, publish_date } = news;
                            return (
                                <Col key={id} md='4'>
                                    <Link to={`/news/${id}`} className="text-danger image-zoom-container h-100" style={{ cursor: 'pointer' }} >
                                        <Card className="d-flex flex-column h-100">
                                            <CardHeader className='bg-transparent p-0 fw-medium fs-4 text-black ff'>{category}</CardHeader>
                                            <div className='image-container border border-1 border-bottom-0'>
                                                <CardImg
                                                    top
                                                    src={image || placeholderimage}
                                                    alt={title}
                                                    loading="lazy"
                                                    className='zoom-image h-100'
                                                    onError={handleImageError}
                                                />
                                            </div>
                                            <CardBody className="card-inner p-3 border border-1 border-bottom-0">
                                                <CardTitle tag="h6" className='ff text-black fw-bold'>{title}</CardTitle>
                                                <CardText className='ff'>
                                                    {typeof summary === 'string' ? truncateText(summary, 80) : ''}
                                                </CardText>
                                            </CardBody>
                                            <CardFooter className='bg-transparent border border-1 border-top-0 text-gray ff d-flex justify-content-between'>
                                                <p>Read More</p>
                                                <span>{new Date(publish_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            ) : (
                <p>No results found.</p>
            )
            }
        </div >
    );
};

export default SearchNews;
