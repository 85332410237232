import React from 'react'
import NewsDetails from '../components/common/NewsDetails'
import ScrollToTopButton from '../components/common/ScrollToTopButton'
import HomeLink from '../components/common/HomeLink'

const DetailNews = () => {
    return (
        <>
            <NewsDetails />
            <ScrollToTopButton />
            <HomeLink />
        </>
    )
}

export default DetailNews
