import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap justify-content-center">
          <div className="nk-footer-copyright">
            &copy; {currentYear}{" "}
            <a href={process.env.REACT_APP_FOOTER_URL}>
              {process.env.REACT_APP_FOOTER_TITLE}
            </a>{" "}
            <span> | </span> All Rights Reserved
            <Col md={12} className="text-center">
              <p className='text-capitalize' style={{ cursor: 'pointer', fontSize: '10px' }}>
                <Link to="#">privacy policy</Link>
                <span> | </span>
                <Link to="#">terms of use</Link>
                <span> | </span>
                <Link to="#">Cookies</Link>
              </p>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;