import React from 'react'
import PoliticHome from './politicComp/PoliticHome'
import PoliticNews from './politicComp/PoliticNews'
import ScrollToTopButton from '../components/common/ScrollToTopButton'
import HomeLink from '../components/common/HomeLink'

const Politic = () => {
    return (
        <>
            <PoliticHome />
            <PoliticNews />
            <HomeLink />
            <ScrollToTopButton />
        </>
    )
}

export default Politic
