import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Newsmenus } from "../../menu/MenuData";
import { useTheme } from '../../provider/Theme';
import Menualt from "../../menu/Menualt.jsx";

const SubNav = ({ fixed, className, ...props }) => {
    const [sticky, setSticky] = useState(false);
    const theme = useTheme();

    const headerClass = classNames({
        "nk-header": true,
        "nk-header-fixed": fixed,
        "has-fixed": sticky,
        [`is-light`]: theme.header === "white",
        [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
        [`${className}`]: className,
    });

    useEffect(() => {
        let _item_offset = 30;
        if (window.scrollY > _item_offset) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    }, [])

    return (
        <>
            <div>
                <div className="container-xl wide-xl">
                    <Menualt menuData={Newsmenus} />
                </div>
            </div>
        </>
    )
}

export default SubNav
