import React, { useEffect, useState } from 'react'
import SectionOne from '../Common/SectionOne'
import entertain from '../../../images/personaimg/Temp/01-deadpool-wolverine.jpg'
import entertain2 from '../../../images/personaimg/Temp/ent4.jpg'
import entertain3 from '../../../images/personaimg/Temp/ent5.jpg'
import { getRequest } from '../../../utils/api-header'
import { Container } from 'reactstrap'
import { ShimmerSimpleGallery } from 'react-shimmer-effects'

const staticEntertainNews = [
  { id: 1, title: 'entertain 1', image: entertain, headline: '‘Deadpool & Wolverine’s’ four TOTALLY INSANE cameos explained' },
  { id: 2, title: 'entertain 2', image: entertain2, headline: 'Robert Downey Jr. is returning to the Marvel Cinematic Universe in new ‘Avengers’ movie' },
  { id: 3, title: 'entertain 3', image: entertain3, headline: 'Aerosmith is retiring from touring as a ‘full recovery’ of Steven Tyler’s vocal cord injury is ‘not possible’' },
  { id: 4, title: 'entertain 4', image: entertain, headline: '‘Deadpool & Wolverine’s’ four TOTALLY INSANE cameos explained' },
];

const EntertainNews = () => {
  const [entertainNews, setEntertainNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await getRequest('api/v1/public/get-public-news?category=entertainment&limit=35&order=desc');

        if (response.status && response.data) {
          const fetchedNews = response.data.news && response.data.news.length > 0 ? response.data.news.slice(19, 31) : staticEntertainNews;
          setEntertainNews(fetchedNews);
        } else {
          setEntertainNews(staticEntertainNews);
        }
      } catch (error) {
        console.error("Error fetching football news:", error);
        setEntertainNews(staticEntertainNews);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  return (
    <Container>
      {loading ? (
        <>
          <div>
            {[...Array(3)].map((_, index) => (
              <ShimmerSimpleGallery card imageHeight={300} caption />
            ))}
          </div>
        </>
      ) : (
        <SectionOne categoryTitle="More News" newsData={entertainNews} />
      )}
    </Container>
  )
}

export default EntertainNews
