import React, { useState, useEffect, createContext, useContext } from "react";
import Blogdetailcard from "./Blogdetailcard";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Blog.css";
import { getCookie } from "../utils/Utils";
import "../assets/css/blog.css";
import { CategoryContext } from "../utils/Context";
import { ShimmerPostDetails } from "react-shimmer-effects";

export default function Blogdetails(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useContext(CategoryContext);
  const [categoryCount, setCategoryCount] = useState({});

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
        const formData = new FormData();
        if (selectedCategory !== "") {
          formData.append("category_id", selectedCategory);
        }

        formData.append("id", id);
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setBlog(data.data?.blogs);
          } else {
            console.error("Error fetching blog data", data.message);
          }
        } else {
          console.error("Error fetching blog data", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    const fetchRecentPosts = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-list-blogs`;
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setRecentPosts(data.data.blogs);
          } else {
            console.error("Error fetching recent posts", data.message);
          }
        } else {
          console.error("Error fetching recent posts", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchBlogData();
    fetchRecentPosts(selectedCategory);
  }, [id]);
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-category-count`;
        const formData = new FormData();
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            const categoryCounts = {};
            data.data.forEach((item) => {
              categoryCounts[item.category] = item.count;
            });
            setCategoryCount(categoryCounts);
          } else {
            console.error("Error fetching blog data", data.message);
          }
        } else {
          console.error("Error fetching blog data", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchBlogData();
  }, [selectedCategory]);

  const categories = [
    { id: 0, name: "TECH TRENDS" },
    { id: 1, name: "ARTIFICIAL INTELLIGENCE" },
    { id: 2, name: "PRODUCT REVIEWS" },
    { id: 3, name: "CLOUD COMPUTING" },
    { id: 4, name: "TECH NEWS" },
    { id: 5, name: "TECH CONFERENCES" },
  ];
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`/category/${category}`);
  };

  if (!blog) {
    return <div>
      {/* Loading... */}
      <ShimmerPostDetails card cta variant="SIMPLE" />
      <ShimmerPostDetails card cta variant="EDITOR" />
    </div>;
  }
  const isVideo =
    !blog.blog_image ||
    blog.blog_image === null ||
    blog.blog_image.trim() === "";
  // const BlogShortDesc = blog.description.slice(0, 100);
  // const Blogdesc = blog.description.slice(100);

  return (
    <div className="blogdetails pb-5 container">
      {/* container  */}
      <div className="row d-flex py-4 justify-content-center">
        <div className="blog-detail-page col-md-8 col-12">
          <Blogdetailcard
            Blogimg={blog.blog_detail_image}
            Blogtitle={blog.title}
            Blogdesc={blog.description}
            BlogVideo={blog.blog_video}
            is_video={isVideo}
            category_id={blog.categories}
          />
        </div>
        {/* Sidebar */}
        <div id="sidebar" className="col-md-4 col-12">
          <div className="sticky-top row d-flex justify-content-center">
            <div className="">
              <div className="d-flex flex-column px-2 py-2 border rounded-4">
                <div
                  className="rounded px-2 py-2 mt-2 text-start fw-bold text-light me-auto"
                  style={{
                    background:
                      process.env.REACT_APP_BUTTON_BACKGROUND_COLOR,
                  }}
                >
                  Recent Posts
                </div>
                <ul className="list-group my-2 text-start list-group-flush">
                  {recentPosts.map((blog, index) => (
                    <li
                      key={index}
                      className="list-group-item my-2 rounded border-bottom-0"
                      style={{
                        borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`,
                        backgroundColor:
                          process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR,
                      }}
                    >
                      <Link
                        to={`/Blogdetails/${blog.id}`}
                        className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                        style={{ textAlign: "justify" }}
                      >
                        {blog.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="d-flex flex-column px-2 py-2 border rounded-4 mt-4">
                <div
                  className="rounded px-2 py-2 mt-2 text-start fw-bold text-light me-auto"
                  style={{
                    background:
                      process.env.REACT_APP_BUTTON_BACKGROUND_COLOR,
                  }}
                >
                  Categories
                </div>
                <ul className="list-group my-2 text-start list-group-flush">
                  {categories.map((category, index) => (
                    <li
                      key={index}
                      className="list-group-item my-3 rounded border-bottom-0"
                      style={{
                        borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`,
                        backgroundColor:
                          process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR,
                      }}
                    >
                      <p
                        className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                        onClick={() => handleCategoryClick(category.name)}
                        style={{ textAlign: "justify", cursor: 'pointer' }}
                      >
                        {category.name}({categoryCount[category.name]})
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Blogdetailsfoot w-100">
        <h5>
          <Link to="/" className="text-light">
            Explore More Blogs from {process.env.REACT_APP_PROJECT_NAME_Blog}{" "}
            Blogs...!
          </Link>
        </h5>
      </div>
    </div>
  );
}
