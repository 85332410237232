import React from "react";
import classNames from "classnames";
import { NavLink, Link } from "react-router-dom";
import { Icon } from "../../Components/Component";

const MenuItem = ({ icon, link, text, sub, newTab, badge, ...props }) => {
  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const menuItemClass = classNames({
    "nk-menu-item": false,
    // "has-sub": sub,
    "active current-page": currentUrl === process.env.PUBLIC_URL + link,
  });
  return (
    <li className={menuItemClass}>
      {newTab ? (
        <Link
          to={`${process.env.PUBLIC_URL + link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="nk-menu-link"
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text fs-6 fw-bold">{text}</span>
        </Link>
      ) : (
        <NavLink
          to={`${process.env.PUBLIC_URL + link}`}
          className={`nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
          onClick={(e) => {
            sub && e.preventDefault();
          }}
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text fs-6 fw-bold">{text}</span>
          {badge && <span className="nk-menu-badge">{badge}</span>}
        </NavLink>
      )}
    </li>
  );
};

const Menualt = ({ menuData }) => {
  return (
    <ul className="nk-menu nk-menu-main d-flex justify-content-lg-center justify-content-start">
      {menuData.map((item) =>
        <MenuItem
          key={item.text}
          link={item.link}
          icon={item.icon}
          text={item.text}
          badge={item.badge}
        />
      )}
    </ul>
  );
}

export default Menualt
