import React, { useState, useEffect, useContext } from 'react';
import Blogitem from './Blogitem';
import Blogitem1 from './Blogitem1';
import Blogdetailcard from './Blogdetailcard';
import Blogdetails from './Blogdetails';
import { Link , useParams} from 'react-router-dom';
import './Blog.css';
import { getCookie } from '../utils/Utils';
import { CategoryContext } from '../utils/Context';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { ShimmerContentBlock} from "react-shimmer-effects";


export default function Category() {
    const { id } = useParams();
    const [blogs, setBlogs] = useState([]);
    const [recentPosts, setRecentPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useContext(CategoryContext);
    const [categoryCount, setCategoryCount] = useState({});
    

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
                const formData = new FormData();
                if(selectedCategory !== ""){
                    formData.append("category_id", selectedCategory);
                }
                const response = await fetch(apiUrl, {
                    headers: {
                        authToken: getCookie("authToken"),
                    },
                    method: "POST",
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.status) {
                        // setCategoryCount(data.data);
                        if(data.data?.blogs){
                            setBlogs(data.data?.blogs);
                        }else{
                            setBlogs([]);
                        }
                    } else {
                        console.error("Error fetching blog data", data.message);
                    }
                } else {
                    console.error("Error fetching blog data", response.statusText);
                }
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };

        fetchBlogData();
    }, [selectedCategory]);

    useEffect(() => {
        const fetchBlogData = async () => {
          try {
            const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-category-count`;
            const formData = new FormData();
            const response = await fetch(apiUrl, {
              headers: {
                authToken: getCookie("authToken"),
              },
              method: "POST",
              body: formData,
            });
    
            if (response.ok) {
              const data = await response.json();
              if (data.status) {
                const categoryCounts = {};
                data.data.forEach((item) => {
                    categoryCounts[item.category] = item.count;
                });
                setCategoryCount(categoryCounts);
              } else {
                console.error("Error fetching blog data", data.message);
              }
            } else {
              console.error("Error fetching blog data", response.statusText);
            }
          } catch (error) {
            console.error("Fetch error:", error);
          }
        };
    
        fetchBlogData();
      }, [selectedCategory]);

    // Array of categories
    const categories = [
        { id: 0, name: "TECH TRENDS" },
        { id: 1, name: "ARTIFICIAL INTELLIGENCE" },
        { id: 2, name: "PRODUCT REVIEWS" },
        { id: 3, name: "CLOUD COMPUTING" },
        { id: 4, name: "TECH NEWS" },
        { id: 5, name: "TECH CONFERENCES" }
    ];
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };
    return (
        <div className="container blog mb-5 pt-3">
            {/* Blog Content */}
            <div className="row d-flex my-4 align-items-center">
                <div className="col-md-8 col-12">
                    {blogs.length > 0 ? (
                        blogs.map((blog, index) => {
                            const isVideo =
                            !blog.blog_image ||
                            blog.blog_image === null ||
                            blog.blog_image.trim() === "";
                            if (index % 2 === 0) {
                            return (
                                <div key={blog.id}>
                                <Blogitem
                                    id={blog.id}
                                    title={blog.title}
                                    author={blog.author}
                                    description={
                                        generateEditor(blog.description)
                                    }
                                    long_description={blog.description}
                                    video={blog.blog_video}
                                    Img={blog.blog_image}
                                    is_video={isVideo}
                                    created_at={blog.created_at}
                                    position="right"
                                />
                                <hr className="silo-divider"></hr>
                                </div>
                            );
                            } else {
                            return (
                                <div key={blog.id}>
                                <Blogitem
                                    id={blog.id}
                                    title={blog.title}
                                    author={blog.author}
                                    description={
                                        generateEditor(blog.description)
                                    }
                                    long_description={blog.description}
                                    video={blog.blog_video}
                                    Img={blog.blog_image}
                                    is_video={isVideo}
                                    created_at={blog.created_at}
                                    position="left"
                                />
                                <hr className="silo-divider"></hr>
                                </div>
                            );
                            }
                        })
                        ) : (
                            <>
                            {/* <p className="fs-4 fw-bold">No blogs data is available</p> */}
                            <ShimmerContentBlock title text cta thumbnailWidth={370} thumbnailHeight={370} />
                            <ShimmerContentBlock title text cta thumbnailWidth={370} thumbnailHeight={370} />
                          </>
                        )}
                </div>
                {/* Sidebar */}
                <div id="sidebar" className="col-12 col-md-4 ps-4">
                    <div className="sticky-top row d-flex justify-content-center">
                        {/* <div>
                            <div className="input-group border px-2 py-2 rounded-4">
                                <input id="srchfd" type="text" className="form-control" placeholder="Search" aria-label="Input group example" aria-describedby="basic-addon1" />
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="bi bi-search"></i>
                                </span>
                            </div>
                        </div> */}
                        <div className="mt-4">
                            <div className="d-flex flex-column px-2 py-2 border rounded-4 my-2 ">
                                <div className="rounded px-2 py-2 mt-2 text-start fw-bold text-light me-auto" style={{ background: process.env.REACT_APP_BUTTON_BACKGROUND_COLOR }}>Recent Posts</div>
                                <ul className="list-group my-2 text-start list-group-flush">
                                    {recentPosts.map((blog, index) => (
                                        <li key={index} className="list-group-item my-2 rounded border-bottom-0" style={{ borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`, backgroundColor: process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR }}>
                                            <Link to={`/Blogdetails/${blog.id}`} className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`} style={{ textAlign: 'justify' }}>{blog.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="d-flex flex-column px-2 py-2 border rounded-4 mt-4 ">
                                <div className="rounded px-2 py-2 mt-2 text-start fw-bold text-light me-auto" style={{ background: process.env.REACT_APP_BUTTON_BACKGROUND_COLOR }}>Categories</div>
                                <ul className="list-group my-2 text-start list-group-flush">
                                    {categories.map((category, index) => (
                                        <li key={index} className="list-group-item my-3 rounded border-bottom-0" style={{ borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`, backgroundColor: process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR }}>
                                            <Link to="#" className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}  onClick={() => handleCategoryClick(category.name)} style={{ textAlign: 'justify' }}>{category.name}({categoryCount[category.name]})</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const generateEditor = (description) => {
    let editorComponent;
    try {
      const contentState = convertFromRaw(JSON.parse(description));
      let truncatedText = contentState.getPlainText().substring(0, 150);
      if (contentState.getPlainText().length > 150) {
        truncatedText += '...';
      }
      const truncatedContentState = convertFromRaw({
        entityMap: {},
        blocks: [{
          text: truncatedText,
          type: 'unstyled',
          entityRanges: [],
        }],
      });
      const truncatedEditorState = EditorState.createWithContent(truncatedContentState);
      editorComponent = (
        <Editor
          editorState={truncatedEditorState}
          toolbarHidden
          readOnly
        />
      );
    } catch (error) {
      console.error("Error parsing JSON:", error);
      editorComponent = <p>Error parsing description</p>;
    }
    return editorComponent;
  };
  