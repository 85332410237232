import React, { useState, useEffect } from "react";
import { DropdownToggle, DropdownMenu, Dropdown, Spinner } from "reactstrap";
import Icon from "../../../../Components/icon/Icon";
import { useThemeUpdate } from "../../../provider/Theme";
import { Link } from "react-router-dom";

const NotificationItem = (props) => {
  const { icon, iconStyle, text, time, id } = props;
  return (
    <div className="nk-notification-item" key={id} id={id}>
      <div className="nk-notification-icon">
        <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="nk-notification-time">{time}</div>
      </div>
    </div>
  );
};

const Notification = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        if (open) {
          setLoading(true);
          const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications`);
          setTimeout(async () => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setNotifications(data.data.notification);
            setLoading(false);
          }, 500);
        }
      } catch (error) {
        setError("Error fetching notifications");
        setLoading(false);
      }
    };
    fetchNotifications();
  }, [open]);

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={() => setOpen((prevState) => !prevState)}>
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-info">
          <Icon name="bell" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
        </div>
        <div className="dropdown-body">
          {loading && (
            <div className="text-center mt-2 mb-2">
              <Spinner color="dark" size="sm" className="text-center" />
            </div>
          )}
          {!loading && !error && (
            <div className="nk-notification">
              {notifications.map((item) => (
                <NotificationItem
                  key={item.id}
                  id={item.id}
                  icon={item.icon}
                  iconStyle={item.iconStyle}
                  text={item.text}
                  time={item.time}
                />
              ))}
            </div>
          )}
          {error && <p>{error}</p>}
        </div>
        <div className="dropdown-foot center">
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
            <strong>View All</strong>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
