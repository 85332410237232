import React from 'react'
import EntertainHome from './entertainComp/EntertainHome'
import EntertainNews from './entertainComp/EntertainNews'
import ScrollToTopButton from '../components/common/ScrollToTopButton'
import HomeLink from '../components/common/HomeLink'

const Entertain = () => {
    return (
        <>
            <EntertainHome />
            <EntertainNews />
            <HomeLink />
            <ScrollToTopButton />
        </>
    )
}

export default Entertain
