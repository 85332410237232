const menu = [
  {
    icon: "file-docs",
    text: "Tax Filings",
    link: "/dashboard",
    dashboard: true,
  },
  // {
  //   icon: "network",
  //   text: "Other Services",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Notary Services",
  //       link: "notary-list",
  //     },
  //     {
  //       text: "Printing Services",
  //       link: "/charts/knobs",
  //     },
  //   ],
  // },
  {
    icon: "contact-fill",
    text: "Feedbacks",
    link: "/contactform",
    dashboard: true,
  },
  {
    icon: "file-docs",
    text: "Tax Filing(Until APR 2024)",
    link: "/taxold",
    dashboard: true,
  },
  // {
  //   icon: "property-alt",
  //   text: "Noatary",
  //   link: "/contactform",
  //   dashboard: true,
  // },
];
export default menu;


export const Newsmenus = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Sports",
    link: "/sports",
  },
  {
    text: "Politics",
    link: "/politics",
  },
  {
    text: "Entertainment",
    link: "/entertainment",
  },
  // {
  //   text: "Technology",
  //   link: "",
  // },
  // {
  //   text: "Science",
  //   link: "",
  // },
  // {
  //   text: "Business",
  //   link: "",
  // },
  // {
  //   text: "Lifestyle",
  //   link: "",
  // },
  // {
  //   text: "International",
  //   link: "",
  // },
  // {
  //   text: "Travel",
  //   link: "",
  // },
]