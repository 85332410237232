import Cookies from "js-cookie";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_NEWS_API_URL || `https://api.silocloud.io/`;
const token = Cookies.get("authToken");

const apiClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        "authToken": token,
        "Content-Type": "application/json",
    },
});

// Example of a GET request
export const getRequest = async (route) => {
    try {
        const response = await apiClient.get(route);
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : error.message;
    }
};

export const postRequest = async (route, data) => {
    try {
        const response = await apiClient.post(route, data); // Use apiClient instead of axios
        return response.data;
    } catch (error) {
        console.error("Error posting data:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : { message: "Something went wrong" };
    }
};



export const postRequestDataForm = async (data, route) => {
    try {
        const response = await apiClient.post(route, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error posting form data:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : error.message;
    }
};

export const postRequestData = async (data, route) => {
    try {
        const response = await apiClient.post(route, data);
        return response.data;
    } catch (error) {
        console.error("Error posting data:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : error.message;
    }
};

export async function getAllNotificationHandler({ limit = 10, offset = 0, module = 6, recent = 10 }) {
    try {
        const response = await apiClient.post('api/v1/public/get-notifications', {
            limit,
            offset,
            module,
            recent,
        });
        return response.data;
    } catch (error) {
        console.error("Error getting notifications:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : error.message;
    }
}

export async function setNotificationCountHandler({ notification_id = 0, status = 1, module = 6 }) {
    try {
        const response = await apiClient.post('api/v1/public/set-notification-status', {
            module,
            status,
            notification_id,
        });
        return response.data;
    } catch (error) {
        console.error("Error setting notification count:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : error.message;
    }
}

export async function getProfileImageHandler({ type = "crop" }) {
    try {
        const response = await apiClient.post('account/get-profile-img', { type }, {
            responseType: 'blob', // To handle image response
        });
        return response.data;
    } catch (error) {
        console.error("Error getting profile image:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : error.message;
    }
}

export const fetchCloudSearchApi = async (route) => {
    try {
        const response = await apiClient.get(route, { cache: "no-store" });
        return response.data;
    } catch (error) {
        console.error("Error fetching cloud search API:", error.response ? error.response.data : error.message);
        return error.response ? error.response.data : error.message;
    }
};
