import React, { useEffect, useState, useRef, useContext } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSpeechSynthesis } from 'react-speech-kit';
import { useNavigate } from 'react-router-dom';
import voiceimage from "../../../../images/personaimg/mic-image.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../../../../Components/Component";
import 'bootstrap/dist/css/bootstrap.min.css';
import "regenerator-runtime/runtime";
import { NewsContext } from '../../../common/navbar/context/NewsContext';

const SpeechMic = () => {
    const [isListening, setIsListening] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [permissionDenied, setPermissionDenied] = useState(false);
    const timeoutRef = useRef(null);
    const [isActive, setIsActive] = useState(true);
    const [displayText, setDisplayText] = useState('');
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [voiceIndex, setVoiceIndex] = useState(null);
    const [voices, setVoices] = useState([]);
    const [ishandlecard, setIshandlecard] = useState(false);
    const { cancel, voices: availableVoices } = useSpeechSynthesis();
    const [manualTranscript, setManualTranscript] = useState('');
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState([]);
    const [history, setHistory] = useState([]);
    const historyEndRef = useRef(null);
    const { setDataSearch } = useContext(NewsContext);
    const [searchTerm, setSearchTerm] = useState('');
    // const [open, setOpen] = useState(false);

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const cx = process.env.REACT_APP_GOOGLE_CX;

    const [togglemic, setToggleMic] = useState(false);

    // Function to toggle microphone
    const toggleMicrophone = () => {
        if (!isModalOpen && !isListening) {
            startListening(true);
            setIsSpeaking(false);
            // handleCommand('hello');
            handleOpenModal();
        } else if (isListening || togglemic) {
            stopListening(true);
            handleCloseModal();
        }
        setToggleMic(!togglemic);
    };

    // Function to check microphone permissions
    const checkMicrophonePermissions = async () => {
        try {
            const permissionStatus = await navigator.permissions.query({ name: "microphone" });
            if (permissionStatus.state === "denied") {
                setPermissionDenied(true);
            }
        } catch (error) {
            console.error("Error checking microphone permissions:", error);
        }
    };

    // Check microphone permissions on component mount
    useEffect(() => {
        checkMicrophonePermissions();
    }, []);

    // Listen for "hello" to trigger modal opening
    useEffect(() => {
        if (transcript.toLowerCase().includes("hello") && !isListening) {
            handleOpenModal();
            const responseText = 'How can I help you?';
            handleSpeechResponse(responseText);
        }
    }, [transcript]);

    useEffect(() => {
        if (availableVoices.length > 0) {
            setVoices(availableVoices);
            const defaultVoiceIndex = 0;
            if (availableVoices.length > defaultVoiceIndex) {
                setVoiceIndex(defaultVoiceIndex);
            }
        }
    }, [availableVoices]);

    // Function to start listening
    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true });
        setIsListening(true);
    };

    // Function to stop listening
    const stopListening = () => {
        SpeechRecognition.stopListening();
        setIsListening(false);
    };

    // Function to open modal
    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsListening(true);
        setIsSpeaking(true);
        resetTranscript();
        setIsActive(!isActive);
    };

    // Function to close modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsSpeaking(false);
        stopListening(true);
        resetTranscript();
        setIsActive(!isActive);
        cancel();
        setIsListening(false);
        SpeechRecognition.abortListening();
    }
    // Function to handle speech response
    const handleSpeechResponse = (text) => {
        if (!voices.length || voiceIndex === null) return;
        stopListening();
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel();
        }
        const voice = voices[voiceIndex];
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = voice;

        setIsSpeaking(true);
        setDisplayText(text);
        setCurrentIndex(0);
        setIsActive(true);
        utterance.onend = () => {
            setIsSpeaking(false);
            setIsActive(false);
            startListening();
        };
        window.speechSynthesis.speak(utterance);
        let index = 0;
        const interval = setInterval(() => {
            setCurrentIndex(index);
            index += 1;
            if (index >= text.length) {
                clearInterval(interval);
            }
        }, 75);
    };

    // Function to fetch Google search results
    const fetchGoogleSearchResults = async (query) => {
        try {
            const response = await fetch(`https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${cx}&q=${query}`);
            const data = await response.json();

            if (data.items) {
                return data.items.map(item => ({
                    title: item.title,
                    link: item.link,
                    snippet: item.snippet
                }));
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            return [];
        }
    };

    // Effect to handle command after a delay
    useEffect(() => {
        if (isListening && transcript) {
            timeoutRef.current = setTimeout(() => {
                handleCommand(transcript);
                resetTranscript();
                setManualTranscript('');
                setSearchResults([]);
            }, 1000);
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isListening, transcript]);

    // Function to toggle speech
    const toggleSpeech = (text) => {
        if (isSpeaking) {
            window.speechSynthesis.cancel();
            setIsSpeaking();
        } else {
            if (text && text.trim() !== '') {
                handleSpeechResponse(text);
            }
        }
    };

    // Function to save history to file
    const saveToFile = () => {
        const textContent = history.map(entry =>
            `Date and Time: ${entry.timestamp}\nTranscript: ${entry.transcript}\nResponse: ${entry.responseText}\n` +
            `Search Results:\n${entry.searchResults.map(result => `Title: ${result.title}, Link: ${result.link}`).join('\n')}\n\n`
        ).join('');

        const blob = new Blob([textContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'voice-recognition.txt';
        link.click();
        URL.revokeObjectURL(url);
    };

    // Function to format text with bold characters
    const formatText = (responseText, currentIndex) => {
        return responseText.split('').map((char, index) => (
            <span key={index} style={{ fontWeight: index <= currentIndex ? 'bold' : 'normal' }} >
                {char}
            </span>
        ));
    };

    // Function to handle card click
    const handleCardClick = (command, event) => {
        setManualTranscript(command);
        event.stopPropagation();
        handleCommand(command);
        setIshandlecard(false);
    };

    // Function to handle copying text
    const handleCopy = (responseText, index) => {
        const resultsText = searchResults
            .slice(0, 5)
            .map(item => `${item.title}\n${item.link}`)
            .join('\n\n');

        navigator.clipboard.writeText(responseText + '\n' + resultsText)
            .then(() => {
                setHistory(prevHistory => {
                    const newHistory = [...prevHistory];
                    newHistory[index].isCopyDisabled = true;
                    newHistory[index].tooltipVisible = true;
                    return newHistory;
                });
                setTimeout(() => {
                    setHistory(prevHistory => {
                        const newHistory = [...prevHistory];
                        newHistory[index].tooltipVisible = false;
                        newHistory[index].isCopyDisabled = false;
                        return newHistory;
                    });
                }, 3000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    // Function to scroll to bottom of history
    const scrollToBottom = () => {
        historyEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Effect to scroll to bottom when history changes
    useEffect(() => {
        scrollToBottom();
    }, [history]);

    const handleCommand = (command) => {
        let responseText = '';
        const lowerCommand = command.toLowerCase();
        if (lowerCommand.startsWith('search for') || lowerCommand.startsWith('find news about')) {
            const searchQuery = lowerCommand.replace(/^(search for|find news about)\s/i, '').trim();
            responseText = `Searching for news about "${searchQuery}"`;
            setDataSearch(searchQuery);
            setTimeout(() => {
                navigate(`/search/${encodeURIComponent(searchQuery)}`);
            }, 1000);
            stopListening(true);
            handleCloseModal();
        } else {
            switch (lowerCommand) {
                case 'hello':
                case 'hi':
                case 'hi Persona':
                    handleOpenModal();
                    responseText = 'How can I help you?';
                    break;
                case 'hello hello':
                    handleOpenModal();
                    responseText = 'How can I help you?';
                    break;

                case 'how are you':
                    responseText = 'I am fine,I think you will be also fine';
                    break;

                case 'okay':
                    responseText = 'Perfect ';
                    break;

                case 'show the sports news':
                case 'show me sports news':
                case 'show me the sports news':
                case 'get me to sports news':
                case 'go to sports news':
                case 'sports news':
                    responseText = 'Here you can see the sports news';
                    setTimeout(() => {
                        navigate('/sports');
                    }, 1000);
                    stopListening(true);
                    handleCloseModal();
                    break;

                case 'show the politics news':
                case 'show me politics news':
                case 'get me to politics news':
                case 'go to politics news':
                case 'politics news':
                case 'show me the politics news':
                    responseText = 'Here you can see the Politics news';
                    setTimeout(() => {
                        navigate(`/politics`);
                    }, 1000);
                    stopListening(true);
                    handleCloseModal();
                    break;

                case 'show the entertainment news':
                case 'show me entertainment news':
                case 'get me to entertainment news':
                case 'go to entertainment news':
                case 'entertainment news':
                case 'show me the entertainment news':
                    responseText = 'Here you can see the Entertainment news';
                    setTimeout(() => {
                        navigate(`/entertainment`);
                    }, 1000);
                    stopListening(true);
                    handleCloseModal();
                    break;

                case 'search news':
                case 'search for news':
                    responseText = 'What topic would you like to search for?';
                    setIsListening(true);
                    break;

                case 'go to homepage':
                case 'homepage':
                case 'home page':
                case 'go back to home page':
                case 'go back to homepage':
                    responseText = 'moving To homepage';
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                    stopListening(true);
                    handleCloseModal();
                    break;

                case 'stop listening':
                case 'stop':
                case 'exit':
                case 'close':
                    handleCloseModal();
                    resetTranscript();
                    stopListening(true);
                    responseText = 'Goodbye!';
                    break;
                default:
                    responseText = 'Please refer to the commands list';
                    // fetchGoogleSearchResults(command);
                    setIsSpeaking(false);
                    break;
            }
        }
        const newEntry = {
            transcript: command,
            responseText: responseText,
            searchResults: [],
            timestamp: new Date().toLocaleString(),
        };
        if (responseText === 'Here are Some best Results') {
            fetchGoogleSearchResults(command).then(results => {
                newEntry.searchResults = results;
                setHistory(prevHistory => [...prevHistory, newEntry]);
                handleSpeechResponse(responseText);
            });
        } else {
            setHistory(prevHistory => [...prevHistory, newEntry]);
            handleSpeechResponse(responseText);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setDataSearch(searchTerm);
            navigate(`/search/${searchTerm}`);
            setSearchTerm('');
        }
    };

    return (
        <>
            <div className="position-voice-search py-1" onClick={toggleMicrophone} style={{ cursor: 'pointer' }}>
                <div className="bg-white border-radius-50" data-toggle="tooltip" data-placement="left bottom" title="Click and say Hello to Speech" >
                    <img src={voiceimage} alt="voice-image" className="img-fluid" width={"15px"} />
                </div>
            </div>
            <Modal isOpen={isModalOpen} toggle={handleCloseModal} className='mt-1'>
                <ModalHeader className="border-0 justify-content-end">
                    <div className="fs-4" style={{ cursor: 'pointer' }}>
                        <Icon
                            name={'cross'}
                            onClick={handleCloseModal}
                            className="text-black border border-1"
                        ></Icon>
                    </div>
                </ModalHeader>
                {permissionDenied ? (
                    <p className="text-center d-flex justify-content-center p-4">
                        Please allow microphone access in your browser settings.
                    </p>
                ) : (
                    <ModalBody className='pt-0'>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="d-flex justify-content-end p-0">
                                    <div className="position-voice-relative d-flex">
                                        <div className="px-2 align-self-center">
                                            <label htmlFor="voice">Select Voice:</label>
                                        </div>
                                        <select id="voice" value={voiceIndex} onChange={(e) => setVoiceIndex(e.target.value)} className="w-75 rounded-1 py-1 px-2" >
                                            {voices.map((voice, index) => (
                                                <option key={voice.voiceURI} value={index}>
                                                    {voice.name} ({voice.lang})
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="h-100px justify-content-center d-flex col-md-12">
                                    <div id="bars" className={isActive ? 'active' : ''}>
                                        {[...Array(5)].map((_, i) => (
                                            <div key={i} className="bar"></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="h-50px">
                                        <h4 className="h-20px text-center ff">
                                            {transcript || manualTranscript}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="row text-start">
                                    <div className="history-container col-md-12 rounded-2 custom-scrollbar overflow-x-hidden"
                                        style={{ whiteSpace: 'pre-wrap', outline: 'none', border: '1px solid #ddd', padding: '5px', maxHeight: '210px', overflowY: 'auto', minHeight: '160px', }} >
                                        {history.map((entry, index) => (
                                            <div key={index} className="history-entry">
                                                <div className="d-flex justify-content-end">
                                                    <p className="fs-6 p-1 px-2 py-1 rounded-2 bg-danger-subtle">
                                                        <strong>
                                                            <Icon name={'check'} />
                                                        </strong>
                                                        {entry.transcript}
                                                    </p>
                                                    <div className="px-1 py-1">
                                                        <Icon name={'user'} className={'fs-5'} />
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="px-1 py-2">
                                                        <Icon name={'mic'} className={'fs-5 py-2'} />
                                                    </div>
                                                    <div className="p-0">
                                                        <p className="bg-danger-subtle rounded-2 p-2">
                                                            {formatText(entry.responseText, entry.currentIndex)}
                                                        </p>
                                                        <div>
                                                            <button className="bg-white border-0 fs-6 cursor-pointer hover-bg-light" data-toggle="tooltip" data-placement="bottom" title="Sound" onClick={() => toggleSpeech(entry.responseText, index)} disabled={isSpeaking} id="isSpeaking" >
                                                                {isSpeaking ? (
                                                                    <Icon name="stop-circle"></Icon>
                                                                ) : (
                                                                    <Icon name="vol"></Icon>
                                                                )}
                                                            </button>
                                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                <button className="mx-1 bg-white border-0 fs-6 hover-bg-light" data-toggle="tooltip" data-placement="bottom" title="Copy" onClick={() => handleCopy(entry.responseText, index)} disabled={entry.isCopyDisabled} >
                                                                    {entry.isCopyDisabled ? (
                                                                        <Icon name="check-thick" />
                                                                    ) : (
                                                                        <Icon name="copy-fill" />
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="fs-10px ps-32">
                                                    <strong></strong> {entry.timestamp}
                                                </p>
                                                {entry.searchResults && entry.searchResults.length > 0 && (
                                                    <ul>
                                                        {entry.searchResults.slice(0, 5).map((item, idx) => (
                                                            <li key={idx}>
                                                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                                    {item.title}
                                                                </a>
                                                                <p>{item.snippet}</p>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ))}
                                        <div ref={historyEndRef} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container ">
                            <div className="row py-3 mt-2 justify-content-center overflow-y-scroll custom-scrollbar">
                                <h5 className="text-start">Commands list :</h5>
                                <div className="col-md-12 row row-cols-1 h-200px custom-scrollbar">
                                    {[
                                        'show me the sports news',
                                        'show me the politics news',
                                        'show me the entertainment news',
                                        'go to homepage',
                                        'search for [your query]',
                                        'find news about [topic]',
                                        'search news',
                                    ].map((suggestion, idx) => (
                                        <div key={idx} className="col py-1 cursor-pointer" onClick={(event) => handleCardClick(suggestion, event)} disabled={ishandlecard} >
                                            <div className="border shadow d-flex align-content-center justify-content-center py-2">
                                                <div className="fs-5 d-flex">
                                                    <Icon name="bulb" />
                                                </div>
                                                <div className="text-center align-content-center align-items-center">
                                                    <p className="m-0">{suggestion}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                )}
            </Modal >
        </>
    )
}

export default SpeechMic