import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Blog from "../Components/Blog.jsx";
import Blogdetails from "../Components/Blogdetails";
import Podcast from "../Components/Podcast";
import Category from "../Components/Category";
import Bloghead from "../Components/Bloghead";
import Index from '../layout/Index';
import NewsLayout from "../layout/NewsLayout.jsx";
import News from "../pages/News.jsx";
import Sports from "../pages/Other/Sports.jsx";
import Politic from "../pages/Other/Politic.jsx";
import Entertain from "../pages/Other/Entertain.jsx";
import DetailNews from "../pages/Other/DetailNews.jsx";
import Error404Modern from "../pages/error/404-modern.js";
import SearchNews from "../pages/Other/SearchNews.jsx";
import Error504Modern from "../pages/error/504-modern.js";

const Pages = () => {
  const location = useLocation();
  const hostname = window.location.hostname;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const blogsDomain = process.env.REACT_APP_BLOG_DOMAIN;
  const newsDomain = process.env.REACT_APP_NEWS_DOMAIN;

  let content;
  if (hostname === blogsDomain) {
    content = (
      <div className="Pages" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR_Blogs }}>
        <Bloghead />
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Index />}>
            <Route index element={<Blog />} />
            <Route path="Blogdetails/:id" element={<Blogdetails />} />
            <Route path="category/:name" element={<Category />} />
            <Route path="podcast" element={<Podcast />} />
          </Route>
        </Routes>
      </div>
    );
  } else if (hostname === newsDomain) {
    content = (
      <div className="Pages" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR_News }}>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<NewsLayout />}>
            <Route index element={<News />} />
            <Route path="news/:id" element={<DetailNews />} />
            <Route path= "search/:searchTerm" element={<SearchNews />} />
            <Route path="sports" element={<Sports />} />
            <Route path="politics" element={<Politic />} />
            <Route path="entertainment" element={<Entertain />} />
          </Route>
          <Route path="errors">
            <Route path="404" element={<Error404Modern />} />
            <Route path="504-mordern" element={<Error504Modern />} />
          </Route>
          <Route path="*" element={<Error404Modern />} />
        </Routes>
      </div>
    );
  }

  return <>{content}</>;
};

export default Pages;
