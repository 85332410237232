import React from 'react'
import homebtn from '../../../images/personaimg/home_black.png'
import { Link } from 'react-router-dom'

const HomeLink = () => {
    const isSmallScreen = window.innerWidth < 768;
    return (
        <div>
            <Link to={"https://silocloud.io/"}>
                <img
                    src={homebtn}
                    alt="Fixed"
                    className="fixed-image"
                    height={isSmallScreen ? 30 : 40}
                    width={isSmallScreen ? 30 : 40}
                />
            </Link>
        </div>
    )
}

export default HomeLink
