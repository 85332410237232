import React, { useState, useEffect } from 'react';
import NewsHero from '../Common/NewsHero';
import sport1 from '../../../images/personaimg/Temp/sport4.jpg';
import sport2 from '../../../images/personaimg/Temp/sport2.jpg';
import img from '../../../images/personaimg/Temp/sport1.jpg';
import img2 from '../../../images/personaimg/Temp/sport3.jpg';
import { ShimmerPostItem } from 'react-shimmer-effects';
import { Container } from 'reactstrap';

const SportsHome = () => {
    const [mainNews, setMainNews] = useState([]);
    const [secondaryNews, setSecondaryNews] = useState([]);
    const [headlines, setHeadlines] = useState([]);
    const [trendingNewsData, setTrendingNewsData] = useState([]);
    const [recentNewsData, setRecentNewsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchNewsData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_NEWS_API_URL}api/v1/public/get-public-news?category=sports&limit=20&order=desc`);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            if (data.status && data.data) {
                setMainNews(data.data.news && data.data.news.length > 0 ? data.data.news[0] : staticMainNews);
                setTrendingNewsData(data.data.news.length > 0 ? data.data.news.slice(1, 5) : staticTrendingNewsData);
                setSecondaryNews(data.data.news && data.data.news.length > 0 ? data.data.news.slice(5, 6) : staticSecondaryNews);
                setHeadlines(data.data.news && data.data.news.length > 0 ? data.data.news.slice(6, 16) : staticHeadlines);
                setRecentNewsData(data.data.news && data.data.news.length > 0 ? data.data.news.slice(16, 20) : staticRecentNewsData);
            } else {
                setFallbackData();
            }
        } catch (error) {
            console.error("Fetch error:", error);
            setFallbackData();
        } finally {
            setLoading(false);
        }
    };

    const setFallbackData = () => {
        setMainNews(staticMainNews);
        setSecondaryNews(staticSecondaryNews);
        setHeadlines(staticHeadlines);
        setTrendingNewsData(staticTrendingNewsData);
        setRecentNewsData(staticRecentNewsData);
    };

    useEffect(() => {
        fetchNewsData();
    }, []);

    const staticMainNews = [
        { id: 1, image: sport1, headline: "‘The marathon, it’s about being patient,’ says Hellen Obiri as she chases elusive Olympic gold medal" }
    ];

    const staticSecondaryNews = [
        { id: 1, image: sport2, headline: "'You're playing for something bigger than yourself': Andy Murray withdraws from singles, looks to doubles in Olympics" }
    ];

    const staticHeadlines = [
        { id: 1, headline: "10 athletes to watch from Team USA at the Summer Olympics" },
        { id: 2, headline: "US men's soccer team dealt heavy defeat by host France in opening Olympic match" },
        { id: 3, headline: "Canadian women’s soccer team staffer given suspended prison sentence over drone incident, prosecutor says" },
        { id: 4, headline: "The ‘Olympic Picasso’ finally finds recognition for his athletics-inspired art" },
        { id: 5, headline: "Olympic security checks block 5,000 people, including 1,000 suspected of meddling and espionage" },
        { id: 6, headline: "France’s forgotten athletes: As the world tunes in to the Olympics, many of Paris’ poorer suburbs see little benefit" },
        { id: 7, headline: "The Olympic Games face a unique set of potential security threats in Paris. Organizers say everyone will be safe" },
    ];

    const staticTrendingNewsData = [
        { id: 1, image: img, title: "Sports News 1", text: "This is the content of the first Sports news item." },
        { id: 2, image: img, title: "Sports News 2", text: "Content for the second Sports news item goes here." },
        { id: 3, image: img, title: "Sports News 3", text: "Here's what the third Sports news item is about." },
        { id: 4, image: img, title: "Sports News 4", text: "Here's what the fourth Sports news item is about." },
    ];

    const staticRecentNewsData = [
        { id: 1, image: img2, title: "Recent News 1", text: "F1 history-maker Zhou Guanyu achieved the 'impossible' by becoming the sport's first Chinese driver" },
        { id: 2, image: img, title: "Recent News 2", text: "Content for the second recent news item goes here." },
        { id: 3, image: img2, title: "Recent News 3", text: "Here's what the third recent news item is about." },
        { id: 4, image: img, title: "Recent News 4", text: "Fourth recent item's content is displayed here." },
    ];

    return (
        <>
            {loading ? (
                <Container>
                    <div className='row'>
                        <div className="col-6">
                            <ShimmerPostItem card title text cta />
                        </div>
                        <div className="col-3">
                            <ShimmerPostItem card title cta />
                        </div>
                        <div className="col-3">
                            {[...Array(2)].map((_, index) => (
                                <ShimmerPostItem
                                    card
                                    title
                                    cta
                                    imageType="thumbnail"
                                    imageWidth={80}
                                    imageHeight={80}
                                    contentCenter
                                />
                            ))}
                        </div>
                    </div>
                </Container>
            ) : (
                <NewsHero
                    title="Sports"
                    mainNews={mainNews}
                    secondaryNews={secondaryNews}
                    headlines={headlines}
                    trendingNewsData={trendingNewsData}
                    recentNewsData={recentNewsData}
                />
            )}
        </>
    );
};

export default SportsHome;
