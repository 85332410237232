import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CategoryContext } from "../utils/Context";
export const GloabalContext = createContext();
const Layout = ({ title, app, ...props }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  return (
    <>
      <GloabalContext.Provider value={{ files, setFiles }}>
      <Head title={`${process.env.REACT_APP_BLOG_HEAD_TITLE}`} />
        <CategoryContext.Provider value={[selectedCategory, setSelectedCategory]}>
          <ToastContainer hideProgressBar autoClose={3000} />
          {/* <AppRoot>
            <AppMain> */}
          <Outlet />
          {/* </AppMain>
          </AppRoot> */}
        </CategoryContext.Provider>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;
