import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { Icon } from "../Component";
import EmojiPicker from "emoji-picker-react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
function ReplyModal({ id }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [usernames, setUsername] = useState("");
  useEffect(() => {
    const accessToken = Cookies.get("accessToken") || "";
    try {
      const decodedToken = jwtDecode(accessToken);
      const username = decodedToken.username;
      setUsername(username);
    } catch (error) {
      console.error("Error decoding token:", error.message);
    }
  }, []);
  const handleEmojiClick = (emojiObject) => {
    setTextValue((prevText) => prevText + emojiObject);
  };
  const showEmojis = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const handleSendMessage = () => {
    const newMessage = {
      text: textValue,
      author: usernames,
      time: new Date().toLocaleTimeString([], { hour: "numeric", minute: "numeric" }),
    };
    setConversation([...conversation, newMessage]);
    setTextValue("");
  };

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        if (modal) {
          const userId = id;
          console.log(userId);
          setLoading(true);
          const response = await fetch(`${process.env.REACT_APP_API_URL}/get-UserMessage`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: userId,
            }),
          });
          setTimeout(async () => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setResponseData(data.data);
            setLoading(false);
          }, 500);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchMessage();
  }, [modal]);

  const handleKeyDown = async (e) => {
    const userId = id;
    if ((e.key === "Enter" && !e.shiftKey) || e.type === "click") {
      e.preventDefault();
      const replyData = {
        id: userId,
        replymessage: textValue.trim(),
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/sent-Reply`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(replyData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        handleSendMessage();
        setTextValue("");

        const responseData = await response.json();
        console.log("Response from API:", responseData);
      } catch (error) {
        console.error("Error sending reply:", error);
      }
    }
  };

  return (
    <>
      <Button className="btn-dim" color="primary" onClick={toggle}>
        <Icon name="reply" />
        <span>Send Reply</span>
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        ></ModalHeader>
        <ModalBody className="bg-gray-100">
          {responseData && (
            <div className="chat is-you">
              <div className="chat-avatar">
                <div className="user-avatar bg-purple">
                  <span>IH</span>
                </div>
              </div>
              <div className="chat-content">
                <div className="chat-bubbles">
                  <div className="chat-bubble">
                    <div className="chat-msg">{responseData.contact_message}</div>
                  </div>
                </div>
                <ul className="chat-meta">
                  <li>{responseData.name}</li>
                  <li>
                    {new Date(responseData.created_at).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })}
                  </li>
                </ul>
              </div>
            </div>
          )}
          {responseData && responseData.replymessage !== null && (
            <>
              <div className="chat is-me">
                <div className="chat-content">
                  <div className="chat-bubbles">
                    <div className="chat-bubble">
                      <div className="chat-msg bg-purple">{responseData.replymessage}</div>
                    </div>
                  </div>
                  <ul className="chat-meta">
                    <li>{usernames}</li>
                    <li>
                      {new Date(responseData.updated_at).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-100 text-center">
                <h6 className="overline-title overline-title-sap">
                  <span>End</span>
                </h6>
              </div>
            </>
          )}
          {conversation.map((message, index) => (
            <div className="chat is-me" key={index}>
              <div className="chat-content">
                <div className="chat-bubbles">
                  <div className="chat-bubble">
                    <div className="chat-msg bg-purple">{message.text}</div>
                  </div>
                </div>
                <ul className="chat-meta">
                  <li>{message.author}</li>
                  <li>{message.time}</li>
                </ul>
              </div>
            </div>
          ))}
          {loading && (
            <>
              <div className="chat is-you">
                <div className="chat-avatar">
                  <Skeleton circle height="100%" containerClassName="user-avatar bg-purple" baseColor="#b6f1c4" />
                </div>
                <div className="chat-content">
                  <div className="chat-bubbles">
                    <div className="chat-bubble">
                      <Skeleton baseColor="#c5c5c5" width={200} className="chat-msg" />
                    </div>
                  </div>
                  <ul className="chat-meta list-unstyled ">
                    <li>
                      <Skeleton width={20} baseColor="#c5c5c5" />
                    </li>
                    <li>
                      <Skeleton width={20} baseColor="#c5c5c5" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="chat is-me">
                <div className="chat-content">
                  <div className="chat-bubbles">
                    <div className="chat-bubble">
                      <Skeleton className="chat-msg bg-white" baseColor="#c5c5c5" width={200} />
                    </div>
                  </div>
                  <ul className="chat-meta">
                    <li>
                      <Skeleton width={20} baseColor="#c5c5c5" />
                    </li>
                    <li>
                      <Skeleton width={20} baseColor="#c5c5c5" />
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter className="p-0">
          {responseData && responseData.replymessage === null && (
            <>
              <div className="nk-chat-editor w-100">
                <div className="nk-chat-editor-form">
                  <div className="form-control-wrap">
                    <textarea
                      className="form-control form-control-simple no-resize"
                      rows={1}
                      id="default-textarea"
                      placeholder="Type your message..."
                      value={textValue}
                      onChange={(e) => setTextValue(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <ul className="nk-chat-editor-tools g-2">
                  <li>
                    <button className="btn btn-undefined btn-sm btn-icon btn-trigger text-primary" onClick={showEmojis}>
                      <Icon name="happyf-fill" />
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-primary btn-round btn-icon" onClick={handleKeyDown}>
                      <Icon name="send-alt" />
                    </button>
                  </li>
                </ul>
              </div>
              <div className="w-100">
                {showEmojiPicker && (
                  <EmojiPicker onEmojiClick={(event) => handleEmojiClick(event.emoji)} className="w-100" />
                )}
              </div>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ReplyModal;
