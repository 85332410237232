import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { Icon } from '../../../Components/Component';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {isVisible && (
                <Button
                    className='p-2 bg-danger-subtle'
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: 1000,
                        cursor: 'pointer',
                        border: '1px',
                        borderRadius: '50%',
                        color: '#000',
                        backgroundColor: '#fff',
                    }}
                >
                    <Icon name={'arrow-up'} className={'icon-hover'} />
                </Button>
            )}
        </>
    );
};

export default ScrollToTopButton;