import React, { useEffect } from "react";
import Head from "./head/Head";
import AppRoot from "./global/AppRoot";
import AppWrap from "./global/AppWrap";
import Footer from "./footer/Footer";
import { Outlet } from "react-router-dom";
import FooterNew from "./common/footer/FooterNew";
import SubNav from "./common/navbar/SubNav";
import SiloHeader from "./header/SiloHeader";
import NewsStates from "./common/navbar/context/NewsStates";
import Cookies from "js-cookie";

const NewsLayout = ({ ...props }) => {
    useEffect(() => {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        Cookies.set("currentURL", window.location.href, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
        });
    }, [])
    return (
        <>
            <Head title={`${process.env.REACT_APP_NEWS_HEAD_TITLE}`} />
            <AppRoot className={'overflow-x-hidden'}>
                <AppWrap className={'bg-white px-0'}>
                    <NewsStates>
                        <SiloHeader fixed />
                        <SubNav fixed />
                        <Outlet />
                        <FooterNew />
                        <Footer />
                    </NewsStates>
                </AppWrap>
            </AppRoot>
        </>
    )
}

export default NewsLayout