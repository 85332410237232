import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { getRequest } from '../../../utils/api-header';
import SectionOne from '../Common/SectionOne';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';

const staticHeadlines = [
    { id: 1, title: "Kamala Harris has energized Democratic voters. But can she expand the map?" },
    { id: 2, title: "Harris goes on offensive on immigration, comparing her record with Trump's" },
    { id: 3, title: "Takeaways from the Senate hearing on the Trump assassination attempt and Secret Service failure" },
];
const PoliticNews = () => {
    const [politicNews, setPoliticNews] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await getRequest('api/v1/public/get-public-news?category=politics&limit=35&order=desc');
                if (response.status && response.data) {
                    const fetchedNews = response.data.news && response.data.news.length > 0 ? response.data.news.slice(19, 31) : staticHeadlines;
                    setPoliticNews(fetchedNews);
                } else {
                    setPoliticNews(staticHeadlines);
                }
            } catch (error) {
                console.error("Error fetching football news:", error);
                setPoliticNews(staticHeadlines);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    return (
        <Container>
            {loading ? (
                <>
                    <div>
                        {[...Array(3)].map((_, index) => (
                            <ShimmerSimpleGallery card imageHeight={300} caption />
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <SectionOne categoryTitle="More News" newsData={politicNews} />
                </>
            )}
        </Container>
    );
};

export default React.memo(PoliticNews);
