import React, { useEffect, useState } from 'react';
import footballImg from '../../../images/personaimg/Temp/football.jpg';
import SectionOne from '../Common/SectionOne';
import { getRequest } from '../../../utils/api-header';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';

const staticFootballNews = [
    { id: 1, title: 'Football1', image: footballImg, headline: 'Football headline 1' },
    { id: 2, title: 'Football2', image: footballImg, headline: 'Football headline 2' },
    { id: 3, title: 'Football3', image: footballImg, headline: 'Football headline 3' },
];

const SportCategories = () => {
    const [sportNews, setSportNews] = useState([]);
    const [loading, setLoading] = useState(true);
    // console.log('sportNews', sportNews);
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await getRequest('api/v1/public/get-public-news?category=sports&limit=32&order=desc');

                if (response.status && response.data) {
                    const fetchedNews = response.data.news && response.data.news.length > 0 ? response.data.news.slice(20, 32) : staticFootballNews;
                    setSportNews(fetchedNews);
                } else {
                    setSportNews(staticFootballNews);
                }
            } catch (error) {
                console.error("Error fetching football news:", error);
                setSportNews(staticFootballNews);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div>
                        {[...Array(3)].map((_, index) => (
                            <ShimmerSimpleGallery card imageHeight={300} caption />
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <SectionOne categoryTitle="More News" newsData={sportNews} />
                </>
            )}
        </>
    );
};

export default SportCategories;
