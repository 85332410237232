import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Block, Icon } from '../../../Components/Component';
import img1 from '../../../images/personaimg/Temp/temp9.jpeg';
import img2 from '../../../images/personaimg/Temp/temp10.jpg';
import img3 from '../../../images/personaimg/Temp/temp11.jpg';
import img4 from '../../../images/personaimg/Temp/temp12.webp';
import img5 from '../../../images/personaimg/Temp/kamala_Haris.avif';
import img6 from '../../../images/personaimg/Temp/temp13.webp';
import img7 from '../../../images/personaimg/Temp/temp14.webp';
import img8 from '../../../images/personaimg/Temp/temp15.webp';
import img9 from '../../../images/personaimg/Temp/temp16.webp';
import { ShimmerPostItem } from 'react-shimmer-effects';
import placeholderimage from '../../../images/News/NewsDefault.png'

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
};

const CardComponent = ({ id, image, category, title, summary, date, onError }) => (
    <Link to={`/news/${id}`} className="text-danger image-zoom-container h-100" style={{ cursor: 'pointer' }} >
        <Card className="d-flex flex-column h-100">
            {/* <CardHeader className='bg-transparent p-0 fw-medium fs-4 text-black ff'>{category}</CardHeader> */}
            <div className='image-container border border-1 border-bottom-0'>
                <CardImg
                    top
                    src={image || placeholderimage}
                    alt={title}
                    loading="lazy"
                    className='zoom-image rounded-0 h-100'
                    onError={onError}
                />
            </div>
            <CardBody className="card-inner p-3 border border-1 border-bottom-0">
                <CardTitle tag="h6" className='ff text-black fw-bold'>{title}</CardTitle>
                <CardText className='ff'>
                    {typeof summary === 'string' ? truncateText(summary, 80) : ''}
                </CardText>
            </CardBody>
            <CardFooter className='bg-transparent border border-1 border-top-0 text-danger ff d-flex justify-content-between'>
                <p>Read More</p> <span className='text-gray'>{new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
            </CardFooter>
        </Card>
    </Link>
);

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_NEWS_API_URL}api/v1/public/get-public-news?limit=30&order=desc`);
                const result = await response.json();
                if (result.status && Array.isArray(result.data.news)) {
                    const allNews = result.data.news;
                    setCategories(allNews.slice(-12));
                } else {
                    setFallbackData();
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setFallbackData();
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const placeholderImage = placeholderimage;

    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    };

    const setFallbackData = () => {
        setCategories(staticCategories);
    };

    const staticCategories = [
        { id: 1, image: img1, category: 'Business', title: 'Paramount cuts 15% of US staff, writes down $6 billion in latest sign of TV troubles', summary: 'Paramount cuts 15% of US staff, writes down $6 billion in latest sign of TV troubles' },
        { id: 2, image: img2, category: 'International', title: '‘Goodbye wrestling’: Indian Physiology appears to announce her retirement after being disqualified from gold medal match', summary: '‘Goodbye wrestling’: Indian Olympian appears to announce her retirement after being disqualified from gold medal match' },
        { id: 3, image: img3, category: 'Sports', title: 'For even the most successful Physians, life after the Games can be like ‘going down that cliff’', summary: 'For even the most successful Olympians, life after the Games can be like ‘going down that cliff’' },
        { id: 4, image: img4, category: 'Travel', title: 'Nigeria, Australia and several other countries warn about travel to UK amid riots', summary: 'Nigeria, Australia and several other countries warn about travel to UK amid riots' },
        { id: 5, image: img5, category: 'Politics', title: 'Some quick example text to build on the card title and make up the bulk of the cards content.', summary: 'Some quick example text to build on the card title and make up the bulk of the cards content. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic, porro.' },
        { id: 6, image: img6, category: 'Technology', title: 'Elon Musk’s X sues ad industry group over alleged advertising ‘boycott’', summary: 'Elon Musk’s X sues ad industry group over alleged advertising ‘boycott’' },
        { id: 7, image: img7, category: 'Education', title: 'Study proves black holes have a ‘plunging region,’ just as Einstein predicted', summary: 'Study proves black holes have a ‘plunging region,’ just as Einstein predicted' },
        { id: 8, image: img8, category: 'Health', title: 'A healthy diet high in fruits and vegetables may reduce your heart and kidney disease risk, study says', summary: 'A diet high in fruits and vegetables may reduce your heart and kidney disease risk, study says' },
        { id: 9, image: img9, category: 'Economy', title: 'Markets saw a massive shift this week. Here’s what happened', summary: 'Markets saw a massive shift this week. Here’s what happened' },
    ];

    const renderShimmerContent = () => (
        <Container className='border-top border-3 border-primary pb-4'>
            <Block className='mt-4'>
                <div className='container-fluid py-3 px-0'>
                    <h4 className='text-capitalize text-primary text-start fw-bold ff' style={{ letterSpacing: '2px' }}>Your Topics<Icon name="chevron-right" className={'align-bottom'} /></h4>
                </div>
                <div className='content'>
                    <Row className='g-3'>
                        {[...Array(9)].map((_, index) => (
                            <Col key={index} md={4}>
                                <ShimmerPostItem card title text cta />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Block>
        </Container>
    );

    if (loading) {
        return renderShimmerContent();
    }

    return (
        <Container className='border-top border-3 border-primary pb-4'>
            <Block className={'mt-4'}>
                <div className='container-fluid py-3 px-0'>
                    <h4 className='text-capitalize text-primary text-start fw-bold ff' style={{ letterSpacing: '2px' }}>Your Topics <Icon name="chevron-right" className={'align-bottom'} /></h4>
                </div>
                <div className='content'>
                    <Row className='g-4'>
                        {categories.map(({ id, image, catgory, title, publish_date, summary }) => (
                            <Col key={id} md={4} lg={4}>
                                <CardComponent id={id} image={image} category={catgory} title={title} summary={summary} date={publish_date} onError={handleImageError} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Block>
        </Container>
    );
};

export default Categories;
